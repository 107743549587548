import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import useSetting from '@src/hooks/useSetting'

export default function Seo({
	description = '',
	lang = 'sv',
	meta = [],
	title = '',
	seoTitle = '',
	pageContextData,
}) {
	const getSetting = useSetting()
	const hideFromSearchEngines = getSetting('hide_from_search_engines', false)

	if (pageContextData) {
		description = pageContextData.description
		title = pageContextData.title
		seoTitle = pageContextData.seoTitle
	}

	/**
	 * Note that this query only works if we have one single site. We define siteMetaData in gatsby-config.js as
	 * some sort of "default" config for all pages.
	 */
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	)

	const metaDescription = description || site.siteMetadata.description

	const metaTags = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: seoTitle,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
	]

	if (hideFromSearchEngines) {
		metaTags.push({
			property: `robots`,
			content: `noindex, nofollow`,
		})
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title ? title : seoTitle}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={metaTags.concat(meta)}
		/>
	)
}
