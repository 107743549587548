import './loading.scss'
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import useSetting from '@src/hooks/useSetting'
import Seo from '@src/components/Seo'
import useTranslations from '@src/hooks/useTranslations'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import MyPages from '@src/components/account/MyPages'
import CircularProgress from '@material-ui/core/CircularProgress'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('loading')

const MyPage = ({ customPageContext = null, location = {} }) => {
	const t = useTranslations()
	const getSetting = useSetting()
	const [initCompleted, setInitCompleted] = useState(false)
	const { isCustomerLoggedIn, language } = useConnect(
		'selectIsCustomerLoggedIn',
		'selectLanguage',
	)
	const salesforceLink = getSetting('salesforce_mypages')

	useEffect(() => {
		if (!isCustomerLoggedIn) {
			navigate(location.pathname.replace('/mitt-kinto', ''))
			return
		} else if (isCustomerLoggedIn && salesforceLink !== '') {
			window.location.replace(salesforceLink)
		}
		if (language !== undefined) {
			setInitCompleted(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, isCustomerLoggedIn])

	let mittKintoContextData = usePageContextData({
		pageName: location.pathname.replace('mitt-kinto', 'mitt-kinto-custom'),
	})

	// UsePageContext hook causes some problems so we just provide a custom pageContext
	// when rendering the page from Pimcore preview.
	if (customPageContext && customPageContext.length > 0) {
		mittKintoContextData = customPageContext
	}

	return initCompleted ? (
		<>
			<Seo title={t('kinto.my-page')} pageContextData={mittKintoContextData} />
			<MyPages />
		</>
	) : (
		<div {...bem('loading')}>
			<CircularProgress size='15rem' />
		</div>
	)
}

export default MyPage
