import './MyBookings.scss'
import React, { useEffect, useState } from 'react'
import useTranslations from '@src/hooks/useTranslations'
import BEMHelper from 'react-bem-helper'
import { navigate } from 'gatsby'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import svLocale from 'date-fns/locale/sv'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { tabletSize } from '@src/javascript/variables'
import ErrorModal from '@src/components/ErrorModal'
import Modal from '@src/components/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { ReactComponent as CarIcon } from '@src/assets/icons/car.svg'
import { ReactComponent as CalendarIcon } from '@src/assets/icons/calendar.svg'
import { ReactComponent as ParkingIcon } from '@src/assets/icons/parking.svg'
import { useConnect } from 'redux-bundler-hook'
import {
	azureApiGatewayGet,
	azureApiGatewayPatch,
} from '@src/api/azureApiGateway/client'
import { get } from 'lodash'
import { localizedUrl } from '@src/utils/navigate'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('my-bookings')

const MyBookings = () => {
	const t = useTranslations()
	const [bookings, setBookings] = useState([])
	const [loading, setLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState(null)
	const [isMobile, setIsMobile] = useState(true)
	const [bookingToCancel, setBookingToCancel] = useState(false)

	const { allVehicleTypes, language, service } = useConnect(
		'selectAllVehicleTypes',
		'selectLanguage',
		'selectService',
	)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(
				typeof window === 'undefined' || window.innerWidth < tabletSize,
			)
		}
		window.addEventListener('resize', handleResize)
		setIsMobile(typeof window === 'undefined' || window.innerWidth < tabletSize)
		setLoading(false)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const fetchScheduledRentals = async () => {
		try {
			const response = await azureApiGatewayGet(
				`booking/scheduled_rental`,
				{},
				{ useToken: true },
			)
			setBookings(response.data.scheduled_rentals)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			if (get(error, 'response.data.messages')) {
				setErrorMessage(error.response.data.messages)
			}
		}
	}

	useEffect(() => {
		fetchScheduledRentals()
	}, [])

	const settings = {
		dots: true,
		infinite: false,
		variableWidth: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					variableWidth: false,
				},
			},
		],
	}

	const convertDateTime = (dateTime) => {
		dateTime = format(parseISO(dateTime), 'd MMM kk.mm', {
			locale: svLocale,
		})
		return dateTime
	}

	bookings.sort(function(a, b) {
		return new Date(a.start_date_time) - new Date(b.start_date_time)
	})

	const div = ({ children }) => <div {...bem('container')}>{children}</div>
	const ContainerComponent = isMobile ? Slider : div

	const cancelBooking = async () => {
		setBookingToCancel(null)
		setLoading(true)
		try {
			await azureApiGatewayPatch(
				`booking/scheduled_rental/${bookingToCancel.booking.id}/cancel`,
				{},
				{ useToken: true },
			)
			await fetchScheduledRentals()
			setLoading(false)
		} catch (error) {
			setErrorMessage('kinto.cancelation.failed')
			setLoading(false)
		}
	}

	const fetchCancellationFees = async (booking) => {
		try {
			const response = await azureApiGatewayGet(
				`booking/scheduled_rental/${booking.id}/cancel_fee`,
				null,
				{ useToken: true },
			)
			const cancellationFee = response.data.cancellation_fee
			if (cancellationFee === 0) {
				setBookingToCancel({
					booking,
					label: 'kinto.confirmation-modal.text',
					cancellationFee,
				})
			} else if (cancellationFee === booking.amount) {
				setBookingToCancel({
					booking,
					label: 'kinto.cancellation.after.booking.start',
					cancellationFee,
				})
			} else {
				setBookingToCancel({
					booking,
					label: 'kinto.cancellation.after.cutoff',
					cancellationFee,
				})
			}
		} catch (error) {
			setErrorMessage('kinto.cancelation.failed')
		}
	}

	return (
		<div {...bem('')}>
			<ContainerComponent {...settings}>
				{bookings.length > 0 ? (
					bookings.map((booking, index) => {
						const vehicleType = getVehicleTypeFromBooking(
							allVehicleTypes,
							booking,
						)
						return (
							<Card {...bem('card')} key={index}>
								<CardMedia
									{...bem('image')}
									component='div'
									alt='Membership image'
									title='Membership image'
								>
									<CarIcon />
								</CardMedia>
								{loading ? (
									<CircularProgress />
								) : (
									<>
										<CardContent {...bem('content')}>
											<div>
												<div {...bem('text', 'time')}>
													<CalendarIcon />
													<p className='h5'>
														{convertDateTime(booking.start_date_time)} -{' '}
														{convertDateTime(booking.end_date_time)}
													</p>
												</div>
												<div {...bem('text')}>
													<CarIcon />{' '}
													<p>{vehicleType ? `${vehicleType.Brand}` : ''}</p>{' '}
												</div>
												<div {...bem('text')}>
													<ParkingIcon />
													<p>{booking.start_station.location.display_name}</p>
												</div>
												<div {...bem('price')}>
													<p className='h5'>
														{booking.price.amount} {booking.price.currency}
													</p>
												</div>
												<p className='small'>
													{service === SITE_TYPES.FLEX
														? t('kinto.my-pages.flex')
														: t('kinto.booking.ratePerKilometer', [
																vehicleType && vehicleType.RatePerKilometer,
														  ])}
												</p>
											</div>
										</CardContent>
										<CardActions {...bem('actions')}>
											<button
												className='button'
												onClick={() => fetchCancellationFees(booking)}
											>
												{t('kinto.my-pages.cancel')}
											</button>
										</CardActions>
									</>
								)}
							</Card>
						)
					})
				) : (
					<Card {...bem('card')}>
						<CardMedia
							{...bem('image')}
							component='div'
							alt='Membership image'
							title='Membership image'
						>
							<CarIcon />
						</CardMedia>
						<CardContent {...bem('content')}>
							<p className='small'>{t('kinto.my-pages.no-bookings')}</p>
						</CardContent>
					</Card>
				)}
			</ContainerComponent>
			<div
				{...(bookings.length > 0
					? { ...bem('button') }
					: { ...bem('button', 'left') })}
			>
				<Button
					aria-label={t('kinto.my-pages.book')}
					variant='contained'
					color='primary'
					fullWidth
					onClick={() => navigate(localizedUrl('/boka-bil', language, service))}
				>
					{t('kinto.my-pages.book')}
				</Button>
			</div>
			{errorMessage && (
				<ErrorModal onClose={() => setErrorMessage(null)}>
					{errorMessage}
				</ErrorModal>
			)}
			{!!bookingToCancel && (
				<Modal
					open={!!bookingToCancel}
					handleClose={() => setBookingToCancel(false)}
					xButton
				>
					<div {...bem('confirmation-modal')}>
						<div>
							<h1>{t('kinto.confirmation-modal.header')}</h1>
							<p>
								{t(bookingToCancel.label, [bookingToCancel.cancellationFee])}
							</p>
						</div>
						<Button
							{...bem('button')}
							color='primary'
							variant='contained'
							fullWidth
							onClick={cancelBooking}
						>
							{t('kinto.confirmation-modal.button-label')}
						</Button>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default MyBookings

function getVehicleTypeFromBooking(vehicleTypes, booking) {
	return vehicleTypes.find(
		(vehicleType) => vehicleType.VehicleType === booking.vehicle_type_id,
	)
}
