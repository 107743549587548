import Client from '@src/api/abstract-rest-client'
import { getProductSlug } from '@src/utils/slug'
import { get } from 'lodash'

const client = new Client()

export async function getProductData({ name, sku }) {
	const result = await client.get({
		endpoint: `/page-data/${getProductSlug({ name, sku })}/page-data.json`,
	})
	return get(result, 'result.pageContext.product')
}

export async function getPageContext({ pageName = '' }) {
	const formattedPageName = pageName.startsWith('/')
		? pageName.substr(1)
		: pageName

	const result = await client.get({
		endpoint: `/page-data/${formattedPageName}/page-data.json`,
	})

	return get(result, 'result.pageContext')
}
