import { useState, useEffect } from 'react'
import { getPageContext } from '@src/api/internal/client'

const usePageContextData = ({ pageName }) => {
	const [pageContextData, setPageContextData] = useState(null)

	useEffect(() => {
		if (pageName) {
			const loadPageContextData = async () => {
				const pageContext = await getPageContext({ pageName })

				setPageContextData(pageContext)
			}

			loadPageContextData()
		}
	}, [pageName])

	return pageContextData
}

export default usePageContextData
