import './MyPages.scss'
import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useConnect } from 'redux-bundler-hook'
import useTranslations from '@src/hooks/useTranslations'
import useCustomerInfo from '@src/hooks/useCustomerInfo'
import BEMHelper from 'react-bem-helper'
import { del, TOKEN_KEY } from '@src/utils/localStorage'
import { setCookie } from '@src/utils/cookieUtil'
import isFuture from 'date-fns/isFuture'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import MyBookings from '@src/components/account/MyBookings'
import Link from '@src/components/Link'
import image from '@src/assets/images/Kinto_membership.jpg'
import { ReactComponent as Account } from '@src/assets/icons/account.svg'
import { ReactComponent as Phone } from '@src/assets/icons/phone_connect.svg'
import { ReactComponent as Mail } from '@src/assets/icons/mail.svg'
import { ReactComponent as Home } from '@src/assets/icons/home.svg'
import { handleClickFinishRegistration } from '@src/components/account/MissingInformationModal'
import { setToken } from '@src/api/azureApiGateway/client'
import useSetting from '@src/hooks/useSetting'
import { localizedUrl } from '@src/utils/navigate'

const NO_CREDITCARD_SET = -1

const bem = new BEMHelper('my-pages')

const MyPages = () => {
	const t = useTranslations()
	const customerInfo = useCustomerInfo()
	const getSetting = useSetting()
	const salesforceLink = getSetting('salesforce_mypages')
	const {
		doResetCustomer,
		isMissingInformation,
		doRefreshCustomerInfo,
		customerInfoLoading,
		language,
		service,
	} = useConnect(
		'doResetCustomer',
		'selectIsMissingInformation',
		'doRefreshCustomerInfo',
		'selectCustomerInfoLoading',
		'selectLanguage',
		'selectService',
	)

	useEffect(() => {
		doRefreshCustomerInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const isLicenseValid = isFuture(
		new Date(customerInfo.drivingLicense.license_expiry_date),
	)

	return (
		<div {...bem('')}>
			<Container>
				<div {...bem('title')}>
					<h1 className='h3'>
						{t('kinto.my-page.welcome')} {customerInfo.firstName}!
					</h1>
					{salesforceLink !== '' && (
						<Button
							className={'ButtonOrange'}
							aria-label={t('kinto.my-pages.salesforce-link')}
							color={'primary'}
							variant={'contained'}
							to={salesforceLink}
							component={Link}
						>
							{t('kinto.my-pages.salesforce-link')}
						</Button>
					)}
				</div>
				<Divider />
				<div {...bem('section')}>
					<div {...bem('sub-title')}>
						<h2 className='h5'>{t('kinto.my-page.bookings')}</h2>
					</div>
					<div {...bem('content-container', 'override-container')}>
						<MyBookings />
					</div>
				</div>
				<div {...bem('section')}>
					<div {...bem('sub-title')}>
						<h2 className='h5'>{t('kinto.my-page.information')}</h2>
					</div>
					<div {...bem('content-container')}>
						<Card {...bem('card')}>
							<CardMedia
								component='img'
								alt='Membership image'
								image={image}
								title='Membership image'
							/>
							<CardContent>
								<div {...bem('card-title')}>
									<b>{t('kinto.my-page.membership')}</b>
								</div>
								<p className='tiny'>
									{t('kinto.my-page.membership-valid-until')}
								</p>
							</CardContent>
						</Card>

						<Card {...bem('card')}>
							<CardContent {...bem('card-content')}>
								{customerInfo && !customerInfoLoading ? (
									customerInfo.customerId && (
										<>
											<span {...bem('card-information')}>
												<Account />
												<b>{`${customerInfo.firstName} ${customerInfo.lastName}`}</b>
											</span>
											<span {...bem('card-information')}>
												<Phone />
												<p className='small'>{customerInfo.phonenumber}</p>
												{!customerInfo.phonenumberVerified && (
													<span className='error tiny'>
														{t('kinto.my-page.not-verified')}
													</span>
												)}
											</span>
											<span {...bem('card-information')}>
												<Mail />
												<span>
													<p className='small'>{customerInfo.email}</p>
													<p className='tiny'>
														{t('kinto.my-page.mail-receipt')}
													</p>
												</span>
											</span>
										</>
									)
								) : (
									<CircularProgress />
								)}
								{customerInfo &&
									customerInfo.address &&
									customerInfo.address.length > 0 && (
										<span {...bem('card-information')}>
											<Home />
											<span>
												<p className='small'>{customerInfo.address[0]}</p>
												<p className='tiny'>{customerInfo.address[1]}</p>
											</span>
										</span>
									)}
							</CardContent>
						</Card>

						<div {...bem('content-stacked')}>
							<Card {...bem('card')}>
								<CardContent>
									<div {...bem('card-title')}>
										<b>{t('kinto.my-page.drivers-license')}</b>
										{!(customerInfo.drivingLicense === undefined) &&
											customerInfo.drivingLicense.is_license_verified &&
											!isLicenseValid && (
												<span className='tiny'>
													{t('kinto.my-page.drivers-license-expired')}
												</span>
											)}
									</div>
									{customerInfo.drivingLicense.is_license_verified ===
										undefined || customerInfoLoading ? (
										<CircularProgress />
									) : !customerInfo.drivingLicense.is_license_verified ? (
										<p className='tiny'>{t('kinto.my-page.not-added')}</p>
									) : (
										isLicenseValid && (
											<p className='tiny'>
												{t('kinto.my-page.drivers-license-valid-until')}{' '}
												{customerInfo.drivingLicense.license_expiry_date}
											</p>
										)
									)}
								</CardContent>
							</Card>

							<Card {...bem('card')}>
								<CardContent>
									<div {...bem('card-title')}>
										<b>{t('kinto.my-page.credit-card')}</b>
									</div>
									{customerInfo.defaultCreditCard && !customerInfoLoading ? (
										<>
											{customerInfo.defaultCreditCard === NO_CREDITCARD_SET && (
												<p className='tiny'>{t('kinto.my-page.not-added')}</p>
											)}
											{customerInfo.defaultCreditCard.last_four_digits && (
												<p className='tiny'>
													**** **** ****{' '}
													{customerInfo.defaultCreditCard.last_four_digits}
												</p>
											)}
										</>
									) : (
										<CircularProgress />
									)}
								</CardContent>
							</Card>
						</div>
					</div>
				</div>
				<div {...bem('bottom-container')}>
					{isMissingInformation === true && (
						<Button
							{...bem('bottom-container', 'finish')}
							aria-label={t('kinto.my-page.finish-registration')}
							variant='contained'
							color='primary'
							onClick={() => handleClickFinishRegistration(customerInfo)}
						>
							{t('kinto.my-page.finish-registration')}
						</Button>
					)}
					<button
						className='button'
						aria-label={t('kinto.my-page.log-out')}
						onClick={() => {
							del(TOKEN_KEY)
							setCookie(TOKEN_KEY, '')
							setToken(null)
							doResetCustomer()
							navigate(localizedUrl('/', language, service))
						}}
					>
						{t('kinto.my-page.log-out')}
					</button>
				</div>
			</Container>
		</div>
	)
}

export default MyPages
